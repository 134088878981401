import  React, { useEffect, useState }  from "react";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { AppHeader } from "../../components/main-components/header";
import { AppFooter } from "../../components/main-components/footer";
import { LeftNav } from "../../components/main-components/left-nav";
import { RightNav } from "../../components/main-components/right-nav";

import { faFilePdf, faQuestionCircle, faUser } from "@fortawesome/free-regular-svg-icons";
import { faBook, faCaretRight, faGraduationCap, faSuitcase } from "@fortawesome/free-solid-svg-icons";
import { getAllLectureNotes, getAllResearchProjects, loadAllEbooks, loadAllQuestions, loadAllUsers, loadAllVideos } from "../../services/documents/documents.context";
import { Usersdata } from "../../components/cards/users-data";
import { Lecturedata } from "../../components/cards/lecture-notes.data";
import { Loader } from "../../components/utilities/loader";
import { Projectdata } from "../../components/cards/project.data";
import { Ebookdata } from "../../components/cards/ebook.data";
import { Videodata } from "../../components/cards/video.data";
import { Questionsdata } from "../../components/cards/questions.card";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons/faCaretLeft";
import { useAuth } from "../../services/authentication/authentication.context";

import "../../styles/dashboard.css";
import "../../styles/dashboard-data.css";
import "../../styles/alert.css";



export const Dashboard = () => {

    const [itesmCnt, setItemsCnt] = useState({
        notesCount: 0,
        projectsCount: 0,
        ebooksCount: 0,
        questionsCount: 0,
        videosCount: 0,
        usersCount: 0
    })

    const [notes, setNotes] = useState([]);
    const [projects, setProjects] = useState([]);
    const [ebooks, setEbooks] = useState([]);
    const [videos, setVideos] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);

    const [chosen, setChosen] = useState("users");
    const [pageNum, setPageNum] = useState(1);
    const [showLeft, setShowleft] = useState(false);

    const { token } = useAuth();

    const paginateData = (page, data) => {
        const pageData = data.slice((page - 1) * 10, ((page - 1) * 10) + 10);
        return pageData;
    } 

    useEffect(() => {
        ( async () => {
            setLoading(true);
            /** all users */
            const allUsers = await loadAllUsers();
            setItemsCnt((cnt) => ({...cnt, usersCount: allUsers.length}))
            setUsers(paginateData(pageNum,allUsers));

            /** all lecture notes */
            const allNotes = await getAllLectureNotes();
            setItemsCnt((cnt) => ({...cnt, notesCount: allNotes.length}))
            setNotes(paginateData(pageNum,allNotes));

            /** all projects */
            const allProjects = await getAllResearchProjects();
            setItemsCnt((cnt) => ({...cnt, projectsCount: allProjects.length}))
            setProjects(allProjects);

            /** all ebooks */
            const allEbooks = await loadAllEbooks();
            setItemsCnt((cnt) => ({...cnt, ebooksCount: allEbooks.length}))
            setEbooks(allEbooks);

            /** all videos */
            const allVideos = await loadAllVideos();
            setItemsCnt((cnt) => ({...cnt, videosCount: allVideos.length}))
            setVideos(allVideos);

            /**all questions */
            const allQuestions = await loadAllQuestions();
            setItemsCnt((cnt) => ({...cnt, questionsCount: allQuestions.length}))
            setQuestions(allQuestions);
            setLoading(false);
        })();
    }, [pageNum]);

    const { usersCount, projectsCount, ebooksCount, questionsCount, videosCount, notesCount} = itesmCnt;
    

    return (
        <div>
            <AppHeader pageName={"admin"} toggleNav={() => setShowleft((l) => !l)} />
            {loading && <Loader />}
            <div className="notes-page">
            <LeftNav visible={showLeft} />
                <div className="main-page">
                    <div className="search-caption">Dashboard</div>
                     <div className="dashboard">
                        <div className="stat-table">
                            <table className="stats">
                                <tr>
                                   <td className="heading">
                                     <FontAwesomeIcon icon={faUser} className="tab-icon" />
                                     Users
                                   </td>
                                   <td className="data">{usersCount}</td>
                                </tr>
                                <tr>
                                   <td className="heading">
                                        <FontAwesomeIcon icon={faFilePdf} className="tab-icon" />
                                        Lecture notes
                                    </td>
                                   <td className="data">{notesCount}</td>
                                </tr>
                                <tr>
                                   <td className="heading">
                                        <FontAwesomeIcon icon={faGraduationCap} className="tab-icon" />
                                        Research projects
                                    </td>
                                   <td className="data">{projectsCount}</td>
                                </tr>
                                <tr>
                                   <td className="heading">
                                        <FontAwesomeIcon icon={faSuitcase} className="tab-icon" />
                                        Job postings
                                    </td>
                                   <td className="data">0</td>
                                </tr>
                                <tr>
                                   <td className="heading">
                                        <FontAwesomeIcon icon={faBook} className="tab-icon" />
                                        Ebooks
                                    </td>
                                   <td className="data">{ebooksCount}</td>
                                </tr>
                                <tr>
                                   <td className="heading">
                                        <FontAwesomeIcon icon={faQuestionCircle} className="tab-icon" />
                                        Questions
                                    </td>
                                   <td className="data">{questionsCount}</td>
                                </tr>
                                <tr>
                                   <td className="heading">
                                        <FontAwesomeIcon icon={faYoutube} className="tab-icon" />
                                        Videos
                                    </td>
                                   <td className="data">{videosCount}</td>
                                </tr>
                            </table>
                        </div>
                     </div>
                     <div className="dash-data">
                        <div className="nav-tabs">
                            <button 
                                className = {classNames(
                                    {"tab-btn": true}, 
                                    {"chosen": chosen === "users"},
                                    {"users": chosen !== "users"}
                                )}
                                onClick={() => {
                                    setChosen("users");
                                    setPageNum(1);
                                }}
                            >
                                Users
                            </button>
                            <button 
                                className = {classNames(
                                    {"tab-btn": true}, 
                                    {"chosen": chosen === "notes"},
                                    {"notes": chosen !== "notes"}
                                )}
                                onClick={() => {
                                    setChosen("notes");
                                    setPageNum(1);
                                }}
                            >
                                Notes
                            </button>
                            <button 
                                className = {classNames(
                                    {"tab-btn": true}, 
                                    {"chosen": chosen === "project"},
                                    {"proj-btn": chosen !== "project"}
                                )}
                                onClick={() => {
                                    setChosen("project");
                                    setPageNum(1);
                                }}
                            >
                                Projects
                            </button>
                            <button 
                                className = {classNames(
                                    {"tab-btn": true}, 
                                    {"chosen": chosen === "resources"},
                                    {"resources-btn": chosen !== "resources"}
                                )}
                                onClick={() => {
                                    setChosen("resources");
                                    setPageNum(1);
                                }}
                            >
                                Ebooks
                            </button>
                            <button 
                                className = {classNames(
                                    {"tab-btn": true}, 
                                    {"chosen": chosen === "video"},
                                    {"jobs-btn": chosen !== "video"}
                                )}
                                onClick={() => {
                                    setChosen("video");
                                    setPageNum(1);
                                }}
                            >
                                Videos
                            </button>
                            <button 
                                className = {classNames(
                                    {"tab-btn": true}, 
                                    {"chosen": chosen === "questions"},
                                    {"qa-btn": chosen !== "questions"}
                                )}
                                onClick={() => {
                                    setChosen("questions");
                                    setPageNum(1);
                                }}
                            >
                                Questions
                            </button>
                        </div>
                        {chosen === "users" && <Usersdata data={users}/>}
                        {chosen === "notes" && <Lecturedata data={notes} />}
                        {chosen === "project" && <Projectdata data={projects} />}
                        {chosen === "resources" && <Ebookdata data={ebooks} />}
                        {chosen === "video" && <Videodata data={videos} />}
                        {chosen === "questions" && <Questionsdata data={questions} />}
                     </div>
                     
                    
                    <div className="page-button-container">
                        <button 
                            className={classNames("page-button",{"disabled": pageNum === 1})} 
                            onClick={() => setPageNum((p) => p - 1)}
                            disabled={pageNum === 1}
                        >
                            <FontAwesomeIcon icon={faCaretLeft} className="page-icon" />
                            Prev
                        </button>
                        <span className="page-label">{pageNum}</span>
                        <button 
                            className="page-button" 
                            onClick={() => setPageNum((p) => p + 1)}
                        >
                            Next
                            <FontAwesomeIcon icon={faCaretRight} className="page-icon" />
                        </button>
                    </div>
                </div>
            <RightNav />
            </div>
            <AppFooter />
        </div>
    );
}