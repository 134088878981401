import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
//import classNames from 'classnames';

import "../../styles/landing.css";


const imagesArray = [
    {
        url: "/media/notebook-logo.png",
        caption: "Welcome to Notebook-BMS, your go-to platform for medical studies and research",
        navTo: "/login"
    },
    {
        url: "/media/lecture-notes.jpg", 
        caption: "Get lecture notes from seasoned instructors",
        navTo: "/lecture_notes"
    },
    {
        url: "/media/research-notes.jpg",
        caption: "Have access to dissertations and peer reviewed research material",
        navTo: "/research"
    },
    {
        url: "/media/question.jpg",
        caption: "Ask your questions, which may be answered by peers, expert instructors, or our AI Assistant",
        navTo: "/questions"
    },
    {
        url: "/media/resources.jpg",
        caption: "Benefit from broad spectrum of resources, which may include PDFs, Youtube videos and other useful materials",
        navTo: "/resources"
    }
]

export const LandingPage = () => {
    return (
        <div className='slide-frame'>
            <div className='slide-container'>
                <Slide
                    transitionDuration={500} 
                    duration={5000} 
                    defaultIndex={0}
                    cssClass='fade-class'
                    canSwipe
                    autoplay= {true}
                >
                    { imagesArray.map((imageObj, indx) => {
                        return (
                            <div 
                                key={indx}
                                className='slide-unit'
                            >
                                <div className='button-box'>
                                    <a href={imageObj.navTo} className='more-link'>
                                        <button className='more-button'>Get started</button>
                                    </a>
                                    <img 
                                        src={imageObj.url} 
                                        alt='' 
                                        className='slide-image' 
                                    />
                                </div> 
                                <div className='slide-caption'>
                                    {imageObj.caption}
                                </div>
                                
                            </div>
                        )
                    })}
                </Slide>
            </div>
        </div>
        
            
          
    );
}