import  React from "react";

import { AppHeader } from "../../components/main-components/header";
import { AppFooter } from "../../components/main-components/footer";
import { LeftNav } from "../../components/main-components/left-nav";
import { RightNav } from "../../components/main-components/right-nav";

export const JobsPage = () => {
    return (
        <div>
            <AppHeader pageName={"jobs"} />
            <div className="notes-page">
                <LeftNav />
                    <div className="main-page">
                        <div className="search-caption">Job Search</div>
                        <div className="search-box">
                            
                            <div className="search-input-box">
                                <img src="/svgs/search-icon.svg" alt="" width={24} className="search-icon" />
                                <input 
                                    type="search" 
                                    className="search-input"  
                                    placeholder="Enter job title to search"
                                />
                            </div>
                            <button className="search-button">Search</button>
                        </div>
                        <div className="notes-box">
                            <div className="search-caption">Job Openings</div>
                        </div>
                        
                        
                    </div>
                <RightNav />
            </div>
            <AppFooter />
        </div>
    );
}