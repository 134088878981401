import React, { useState, useRef } from "react";

import "../../styles/auth.css";
import "../../styles/notes.css";
import "../../styles/search.css";
import "../../styles/main.css";
import "../../styles/index.css";

import { categories } from "../../helpers/mock-data";
import { AppHeader } from "../../components/main-components/header";
import { AppFooter } from "../../components/main-components/footer";
import { LeftNav } from "../../components/main-components/left-nav";
import { Loader } from "../../components/utilities/loader";
import { useAuth } from "../../services/authentication/authentication.context";
import classNames from "classnames";
import { uploadPublication } from "../../services/documents/documents.context";
import { AppAlert } from "../../components/utilities/alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";

export const AddNotesPage = () => {

    const { isLoading, token } = useAuth ();

    const [bookData, setBookData] = useState({
        title: null,
        authors: null,
        publisher: null,
        publishedYear: null,
        category: null,
        document: null,
        type: "Lecture notes"
    });
    const [loading, setLoading] = useState(false);
    const [valError, setValError] = useState({
        caption: null,
        message: null
    });
    const [onErr, setOnErr] = useState(null);
    const [showLeft, setShowleft] = useState(false);

    const handleUpload = async (token , data) => {
        setLoading(true);
        await uploadPublication(token, data);
        setLoading(false);
    }

    const { title, document, authors } = bookData;

    const isNull = (v) => (v === "" || v === undefined || v === null);

    const inputRequired = () => {
        return isNull (title) || isNull(document) || isNull(authors);
    }

    const fileButton = useRef();

    return (
        <div>
            <AppHeader pageName={"notes"}  toggleNav={() => setShowleft((s) => !s)} />
            <div className="notes-page">
            {(loading || isLoading) && <Loader />}
            {onErr && 
                <AppAlert 
                    caption={valError.caption}
                    message={valError.message}
                    close={() => setOnErr(false)}
                    accept={"Ok"}
                    acceptAction={() => setOnErr(false)}
                />
            }
            <LeftNav visible={showLeft} />
                <div className="main-page">

                    <div className="search-caption">Add notes</div>
                        <div className="form-container">
                            <div className="input-container">
                                <span className="input-cap">
                                    Title
                                    <span className="req-asterix">*</span>
                                </span>
                                
                                <input 
                                    type="text" 
                                    placeholder="Title of lecture notes" 
                                    className="input"
                                    onChange={(e) => setBookData((data) => 
                                        ({...data, title: e.target.value})
                                    )}
                                />
                            </div>
                            <div className="input-container">
                                <span className="input-cap">
                                    Author(s)
                                    <span className="req-asterix">*</span>
                                </span>
                                <input 
                                    type="text" 
                                    className="input"
                                    placeholder="First names of authors" 
                                    onChange={(e) => setBookData((data) => 
                                        ({...data, authors: e.target.value})
                                    )}
                                />
                            </div>
                            <div className="input-container">
                                <span className="input-cap">Publisher</span>
                                <input 
                                    type="text" 
                                    className="input"
                                    placeholder="Publisher" 
                                    onChange={(e) => setBookData((data) => 
                                        ({...data, publisher: e.target.value})
                                    )}
                                />
                            </div>
                            <div className="input-container">
                                <span className="input-cap">Published year</span>
                                <input 
                                    type="number" 
                                    className="input"
                                    placeholder="Year published" 
                                    onChange={(e) => setBookData((data) => 
                                        ({...data, publishedYear: e.target.value})
                                    )}
                                    required
                                />
                            </div>
                            <div className="input-container">
                                <span className="input-cap">
                                    Category
                                    <span className="req-asterix">*</span>
                                </span>
                                <select 
                                    type="text" 
                                    name="category" 
                                    id="category" 
                                    className={classNames("input", "category")} 
                                    required
                                    onChange={(e) => setBookData((data) => (
                                        {...data, category: e.target.value}
                                    ))}
                                >
                                    <option value={null} selected disabled>
                                        ---Choose subject area---
                                    </option>
                                    {categories.map((category, i) => (
                                        <option value={category} >{category}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="file-btn-container">
                                <input 
                                    type="file"
                                    className="file-button"
                                    ref={fileButton}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        setBookData((data) => (
                                            {...data, document: e.target.files[0]}
                                        ))
                                    }} 
                                />
                                <button
                                    onClick={() => fileButton.current.click()}
                                    className="file-click"
                                >
                                    <FontAwesomeIcon icon={faFileUpload} style={{marginRight: 8}} />
                                    Select notes file
                                </button>
                            </div>
                            
                            <button 
                                className="form-button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (inputRequired()) {
                                        setOnErr(true);
                                        setValError((err) => (
                                            {
                                                ...err, 
                                                caption: "Error!",
                                                 message: "Please provide all the required data"
                                            }
                                        ))
                                        return;
                                    }else{
                                        setOnErr(false);
                                        setValError((err) => ({...err, caption: null, message: null}));
                                    }
                                    handleUpload(token, {...bookData, type: "Lecture notes"})
                                }}
                            >
                                Upload notes
                            </button>
                        </div>
                    </div>
                </div>
                <AppFooter />
            </div>
        
    )
}