import  React, { useEffect, useState } from "react";

import { AppHeader } from "../../components/main-components/header";
import { AppFooter } from "../../components/main-components/footer";
import { LeftNav } from "../../components/main-components/left-nav";
import { RightNav } from "../../components/main-components/right-nav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";

import "../../styles/questions.css";

import { Loader } from "../../components/utilities/loader";
import classNames from "classnames";
import { formatUrl } from "../../helpers/parse-url";
import { parseTime } from "../../helpers/parse-time";
import { getAnswers } from "../../services/authentication/authentication.services";

export const AnswersPage = () => {

    const [loading, setLoading] = useState(false);
    const [answers, setAnswers] = useState([]);


    const { state } = useLocation();

    const question = state;

    const loadAnswers = async (id) => {
        setLoading(true);
        try {
            const response = await getAnswers(id);
            const res = await response.json();
            if (res?.error) {
                setLoading(false);
                return console.log({error: `Could not get answers: ${res?.error}`});
            }
            setLoading(false);
            return res;
        } catch (e) {
            console.log({error: `Internal error: ${e}`});
        }
    }

    useEffect(() => {
        ( async() => {
            const data = await loadAnswers(question?._id);
            setAnswers(data);
            console.log(data);
        })();
    }, [question]);

    return (
        <div>
            <AppHeader pageName={"questions"} />
            <div className="notes-page">
                {loading && <Loader />}
                <LeftNav />
                    <div className="main-page">
                    <div className="ask-box">
                        <a href="/postquestion" class="ask-button" >
                            <button className="right cap add-notes">
                                <FontAwesomeIcon icon={faPlusCircle}  className="plus-icon"/>
                                <span className="right_txt">Ask question</span>
                            </button>
                        </a>
                    </div>
                       
                        <div className="questions-container">
                            <div className="search-caption">Question</div>
                            <button 
                                className="question-unit" key={question?._id}  
                            >
                                <span className="unit-data">
                                    <img 
                                        className="unit-avatar"
                                        src={ 
                                            question?.poster?.avatar? 
                                            formatUrl(question?.poster?.avatar) :
                                            "/media/peer.png"
                                        }
                                        alt=""
                                    />
                                    <span className="unit-poster">{question?.poster?.names}</span>
                                    <span className="unit-meta">{parseTime(question?.createdAt)}</span>
                                </span>
                                <span className={classNames("question-cap","unit-text")}>
                                    {question?.question}
                                </span>
                            </button>
                            <div className="search-caption">Answers</div>
                            { answers?.map((ans) => {
                                return (
                                    <button 
                                        className="question-unit" key={ans?._id}
                                        
                                    >
                                        <span className="unit-data">
                                            <img 
                                                className="unit-avatar"
                                                src={ 
                                                    ans?.poster?.avatar? 
                                                    formatUrl(ans?.poster?.avatar) :
                                                    "/media/peer.png"
                                                }
                                                alt=""
                                            />
                                            <span className="unit-poster">{ans?.poster?.names}</span>
                                            <span className="unit-meta">{parseTime(ans?.createdAt)}</span>
                                        </span>
                                        <span className={classNames("question-cap","unit-text")}>
                                            {ans?.answer}
                                        </span>
                                    </button>
                                );
                            })}
                        </div>
                    </div>
                <RightNav />
            </div>
            <AppFooter />
        </div>
    );
}