import React, { useState } from "react";

import "../../styles/auth.css";
import "../../styles/index.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { useAuth } from "../../services/authentication/authentication.context";
import { AppAlert } from "../../components/utilities/alert";
import { Loader } from "../../components/utilities/loader";

export const SignupForm = () => {

    const [credentials, setCredentials] = useState({
        email: null,
        password: null,
        repeatPassword: null,
        phoneNumber: null,
        biodata: null,
        names: null
    });

    const [toggleShow, setToggleShow] = useState(false);
    const [toggleShow1, setToggleShow1] = useState(false);

    const [appAlert, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState({
        message: "",
        caption: ""
    });

    const { registerUserAccount, isLoading } = useAuth();

    const { email, password, repeatPassword, phoneNumber, biodata, names } = credentials;

    const validInputs = () => {
        let isNull = (d) => d === null || d === undefined || d === "";
        const required = [email,password,repeatPassword, phoneNumber];
        const nullValue = required.some((v) =>{
            return isNull(v)
        });
        if (nullValue) return false;
        else return true;
    }

    const handlePassword = (password, repeatPassword) => {
        if (password?.length < 8) {
            return "Password is too short!";
        };
        if (password !== repeatPassword) {
            return  "Passwords do not match!";
        };
    }

   return (
       <div className={classNames("scroll-page")}>
        {isLoading && <Loader />}
       {appAlert &&
            <AppAlert
                caption={alertMessage.caption}
                message={alertMessage.message}
                accept={"Ok"}
                acceptAction={() => setAlert(false)}
                close={ () => setAlert(false)} 
            />
        }
           <div className="page-caption">
               <span className="wlc">Welcome!</span>
               <span className="wlc-cap">Create an account</span>
               <span className="wlc-cap">with NOTEBOOK-BMS</span>
           </div>
           <div className="form-container">
                <div className="input-container">
                    <span className="input-cap">Names</span>
                    <input 
                        type="text" 
                        value={names} 
                        className="input"
                        placeholder="Enter your full names"
                        onChange={(e) => setCredentials((cred) => (
                            {...cred, names: e.target.value}
                        )) } 
                    />
                </div>
                <div className="input-container">
                    <span className="input-cap">Email</span>
                    <input 
                        type="email" 
                        value={email} 
                        className="input"
                        placeholder="e.g. mymail@provider.com"
                        onChange={(e) => setCredentials((cred) => (
                            {...cred, email: e.target.value}
                        )) } 
                    />
                </div>
                <div className="input-container">
                    <span className="input-cap">Phone Number</span>
                    <input 
                        type="tel" 
                        value={phoneNumber} 
                        className="input"
                        placeholder="your phone number with area code"
                        onChange={(e) => setCredentials((cred) => (
                            {...cred, phoneNumber: e.target.value}
                        )) } 
                    />
                </div>
                <div className="input-container">
                    <span className="input-cap">bio</span>
                    <textarea  
                        value={biodata}
                        cols={10}
                        rows={10} 
                        className="input"
                        placeholder="say something about yourself"
                        onChange={(e) => setCredentials((cred) => (
                            {...cred, biodata: e.target.value}
                        )) } 
                    />
                </div>
                <div className="input-container">
                        <span className="input-cap">Password</span>
                        <input 
                            type={ toggleShow ? "text" : "password" } 
                            value={password} 
                            className="input"
                            placeholder="type your password"
                            onChange={(e) => setCredentials((creds) => (
                                {...creds, password: e.target.value}
                            ))} 
                        />
                        <button 
                            className="eye-icon"
                            onClick={() => setToggleShow1((t) => !t)}
                        >
                            <FontAwesomeIcon 
                                icon={ toggleShow1 ? faEye : faEyeSlash} 
                            />
                        </button>
                </div>
                <div className="input-container">
                        <span className="input-cap">Confirm password</span>
                        <input 
                            type={ toggleShow ? "text" : "password" } 
                            value={repeatPassword} 
                            className="input"
                            placeholder="type your password"
                            onChange={(e) => setCredentials((creds) => (
                                {...creds, repeatPassword: e.target.value}
                            ))} 
                        />
                        <button 
                            className="eye-icon"
                            onClick={() => setToggleShow((t) => !t)}
                        >
                            <FontAwesomeIcon 
                                icon={ toggleShow ? faEye : faEyeSlash} 
                            />
                        </button>
                </div>

                <div className="other-ops">
                    <span className="ops-text">Already have an account? </span>
                    <a href="/login">Sign in</a>
                </div>

                <button 
                    className="form-button"
                    onClick={ async() => {
                        const validData = validInputs();
                        if (!validData){ 
                            setAlert(true);
                            setAlertMessage(() => ({
                                caption: "Validation error!",
                                message: "Please provide data for all required fields"
                            })); 
                            return;
                        }
                        let passErr = handlePassword(password, repeatPassword);
                        if (passErr) {
                            setAlert(true)
                            setAlertMessage((msg) => ({
                                caption: "Password error!",
                                message: passErr
                            }))
                        }
                        console.log(credentials);
                        const resp  = await registerUserAccount(credentials);
                        if (resp?.regError){
                            setAlert(true);
                            setAlertMessage(() => ({
                                caption: "Registration error!",
                                message: resp?.regError
                            }));
                            return;
                        }

                        if (resp?.success) {
                            setAlert(true);
                            setAlertMessage(() => ({
                                caption: "Success!",
                                message: resp?.success
                            }));
                            return;
                        }
                    }}
                >
                    Register
                </button>
              
           </div>
       </div>
   );
}
