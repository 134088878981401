import React, { useState } from "react";
import classNames from "classnames";
import "../../styles/right-nav.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNotesMedical, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { getAllNotes, getAllprojects } from "../../services/documents/documents.services";
import { eliipsizeText } from "../../helpers/utility-methods";

export const RightNav = () => {

    const [notes, setNotes] = useState([]);
    const [projects, setProjects] = useState([]);

    const getAllLectureNotes = async () => {
        try {
            const response = await getAllNotes();
            const res = await response.json();
            const recents = res.slice(0,5);
            setNotes(recents);
        } catch (e) {
            console.log({error: `Internal server error: ${e}`});
        }
    }

    const getAllResearchProjects = async () => {
        try {
            const response = await getAllprojects();
            const res = await response.json();
            const mostRecent = res.slice(0,5);
            setProjects(mostRecent);
        } catch (e) {
            
        }
    }

    React.useEffect(() => {
        const controller = new AbortController();
        ( async() => {
            await getAllLectureNotes();
            await getAllResearchProjects();
        })();
        ( () => controller.abort() )();
    }, []);

    return (
        <div className ="right_nav">
            <div className ={classNames("right_top", "right_part")}>
                <div className ="project_caps">
                    <span className ={classNames("left", "cap")}>Latest projects</span>
                    <button className ={classNames("right", "cap", "add_proj")}>
                        <FontAwesomeIcon icon={faPlusCircle} />
                        <span className ="right_txt">add project</span>
                    </button>
                </div>
                <div className ={classNames("project_section", "right_section")}>
                    <div className ={classNames("project", "lists")} id="proj-list">
                    {projects?.map((note) => {
                            return (
                                <div
                                    className="project_item" 
                                    key={note?._id}
                                >
                                    <img 
                                        src="/svgs/pdf-icon.svg" 
                                        width="10" 
                                        height="10" 
                                        alt="" 
                                        className = "proj_img" />
                                    <span>{eliipsizeText(note?.title)}</span>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className ={classNames("right_bottom", "right_part")}>
                <div className ="project_caps">
                    <span className ={classNames("left", "cap")}>Latest notes</span>
                    <span className ="right cap">
                        <FontAwesomeIcon icon={faNotesMedical} />
                        <a href="/lecture_notes" className ="right_txt">see all</a>
                        <hr className ="ca_hr" />
                    </span>
                </div>
                <div className ={classNames("note_section", "right_section")}>
                    <div className ={classNames("note", "lists")} id="note-list">
                        {notes.map((note) => {
                            return (
                                <div
                                    className="project_item" 
                                    key={note?._id}
                                >
                                    <img 
                                        src="/svgs/lectur-notes.svg" 
                                        width="10" 
                                        height="10" 
                                        alt="" 
                                        className="proj_img" />
                                    <span>{note?.title}</span>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}