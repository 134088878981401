/** Google auth instructions */

/**
 * Step 1: Install google auth Library
 * $ npm install @react-oauth/google@latest
 */

/**
 * Step 2: Create a google dev console project fo the app
 * and get the client id of the web application part
 */

/**
 * Step 3: Create a google authentication provider
 *  (prefarably using context API)
 */
import React from 'react';

import classNames from "classnames";




export const GLoginscreen = ({loginWithGooogle}) => {
        
    
        // log out function to log the user out of google and set the profile array to null
        // const logOut = () => {
        //     googleLogout();
        //     setProfile(null);
        // };
    
    return (
        <div className="g-form">
            <button 
                onClick={loginWithGooogle}
                className= { classNames("form-button", "google-button")}
            >
                Sign in with Google 
                <img 
                    src="/media/google-logo.png" 
                    alt="" 
                    width={20} 
                    height={20}
                    className="g-logo" 
                />
            </button>
        </div>
    );
}
