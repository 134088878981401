import React from "react";
import { useAuth } from "../../services/authentication/authentication.context";
import { Navigate, Outlet } from "react-router-dom";

const AdminRoute = () => {

  
    const { userData } = useAuth();
    
    //React.useEffect(() => {alert(isAuthenticated)}, [isAuthenticated])

    return (
             userData?.role === "user"  ?  <Navigate to="/myprofile" /> : <Outlet /> 
           );
  
};

export default AdminRoute;