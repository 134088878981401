 import React, { useEffect, useState } from "react";

 import "../../styles/auth.css";
 import "../../styles/index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../services/authentication/authentication.context";
import { Loader } from "../../components/utilities/loader";
import { GLoginscreen } from "./google.auth";

 export const LoginForm = () => {

    const [credentials, setCredentials] = useState({
        email: "",
        password: ""
    });

    const [toggleShow, setToggleShow] = useState(false);

    const { loginUser, isLoading, isAuthenticated, googleLogin } = useAuth();

    const { email, password } = credentials;

    useEffect(() => {
        if (isAuthenticated === true) {
            window.location.href = "/myprofile";
        }
    }, [isAuthenticated])

    return (
        <div className="centered-page">
            {isLoading && <Loader />}
            <div className="page-caption">
                <span className="wlc">Welcome back!</span>
                <span className="wlc-cap">Sign in</span>
            </div>
            <div className="form-container">
                <div className="input-container">
                    <span className="input-cap">Email</span>
                    <input 
                        type="email" 
                        value={email} 
                        className="input"
                        placeholder="e.g. mymail@provider.com"
                        onChange={(e) => setCredentials((cred) => (
                            {...cred, email: e.target.value}
                        )) } 
                    />
                </div>
                <div className="input-container">
                    <span className="input-cap">Password</span>
                    <input 
                        type={ toggleShow ? "text" : "password" } 
                        value={password} 
                        className="input"
                        placeholder="type your password"
                        onChange={(e) => setCredentials((creds) => (
                            {...creds, password: e.target.value}
                        ))} 
                    />
                    <button 
                        className="eye-icon"
                        onClick={() => setToggleShow((t) => !t)}
                    >
                        <FontAwesomeIcon 
                            icon={ toggleShow ? faEye : faEyeSlash} 
                        />
                    </button>
                </div>
                <div className="other-ops">
                    <span className="ops-text">
                        <a href="/recoveryemail">Forgot my password</a>
                    </span>
                </div>
                <button 
                    className="form-button"
                    onClick={() => {
                        loginUser(email, password);
                        setCredentials((creds) => ({...creds, email: "", password: ""}))
                    }}
                  
                >
                    Sign in
                </button>
                <GLoginscreen loginWithGooogle={() => googleLogin()} />
                <div className="other-ops">
                    <span className="ops-text">Don't have an account? </span>
                    <a href="/register">Create an account</a>
                </div>
            </div>
        </div>
    );
 }
 