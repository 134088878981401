import  React, { useState } from "react";

import { AppHeader } from "../../components/main-components/header";
import { AppFooter } from "../../components/main-components/footer";
import { LeftNav } from "../../components/main-components/left-nav";
import { RightNav } from "../../components/main-components/right-nav";


import "../../styles/questions.css";
import "../../styles/ask-question.css";
import "../../styles/auth.css";

import { Loader } from "../../components/utilities/loader";
import { useAuth } from "../../services/authentication/authentication.context";
import { AppAlert } from "../../components/utilities/alert";



export const AskNoteAi = () => {

   
    const [isAlert, setisAlert] = useState(false);
    const [alertObj, setAlertObj] = useState({
        caption: null,
        message: null
    });


    const { token, askNoteAi, isLoading } = useAuth();

    const [question, setQuestion] = useState(null);
    const [answer, setAnswer] = useState(null);
    const [showLeft, setShowleft] = useState(false);

    const handleUpload = async (token, question) => {
        try {
            const response = await askNoteAi(token,question);
            console.log(response);
            if (response){
               slowTypeText(response?.message?.content);
            }
            
        } catch (e) {
            console.log({error: `Internal error! ${e}` });
        }
    }

    const slowTypeText = (input) => {

        let i = 0;
        
        const intervalId = setInterval(() => {
            setAnswer(input.slice(0, i));

            i++;

            if (i > input.length) {
                clearInterval(intervalId);
                
            }
        }, 20);
    }


    const inputRequired = () => {
        let isNull = (d) => d === "" || d === null || d === undefined;
        return (isNull(question) );
    }
    

    return (
        <div>
            <AppHeader 
                pageName={"questions"}
                toggleNav={()=> setShowleft((s) => !s)} 
            />
            <div className="notes-page">
                {isLoading && <Loader />}
              
                { isAlert && 
                    <AppAlert 
                        caption={alertObj.caption}
                        message={alertObj.message}
                        accept={"Ok"}
                        acceptAction={() => setisAlert(false)}
                    />
                }
                <LeftNav visible={showLeft} />
                    <div className="main-page">
                    <div className="search-caption">Ask AI assistant</div>
                        <div className="question-form">
                            <textarea 
                                className="question-input"
                                placeholder="Type your question here"
                                onChange={(e) => setQuestion(e.target.value)} 
                            />
                            {answer &&
                                <div className="answer-input">{answer}</div>
                            }
                            <button 
                                className="form-button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (inputRequired()) {
                                        setisAlert(true);
                                        setAlertObj((err) => (
                                            {
                                                ...err, 
                                                caption: "Error!",
                                                message: "Please provide all the required data"
                                            }
                                        ))
                                        return;
                                    }else{
                                        setisAlert(false);
                                        setAlertObj((err) => ({...err, caption: null, message: null}));
                                    }
                                    handleUpload(token, question)
                                }}
                            >
                                Upload question
                            </button>
                       </div>
                    </div>
                <RightNav />
            </div>
            <AppFooter />
        </div>
    );
}