import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

import "../../styles/buttons.css";

export const AddButton = ({ caption, link }) => {

    const  navigate  = useNavigate();

    const handleAddClick = () => {
        navigate(link);
    }

    return (
        <div className="add-box">
            <button 
                className={classNames("right", "cap", "add-notes")}
                onClick={handleAddClick}
            >
                <FontAwesomeIcon icon={faPlusCircle}  className="plus-icon"/>
                <span className="right_txt">{caption}</span>
            </button>
        
        </div>
    )
}