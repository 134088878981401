import React, { useState } from "react";

import "../../styles/auth.css";
import "../../styles/index.css";
import { useAuth } from "../../services/authentication/authentication.context";
import { Loader } from "../../components/utilities/loader";
import { AppAlert } from "../../components/utilities/alert";

export const SendRecoveryEmail = () => {

   const [email, setEmail] = useState(null);
   const [alert, setAlert] = useState(false);

   const [alertMsg, setAlertMsg] = useState({
        caption: null,
        message: null
   });

   const { isLoading, sendPassRecoveryEmail } = useAuth();
   
    const handleSubmit = async () => {
        const resp = await sendPassRecoveryEmail(email);
        if (resp?.error){
            setAlert(true);
            setAlertMsg((msg) => ({
                ...msg,
                caption: "Error",
                message: resp?.error
            }));
            return;
        }

        setAlert(true);
        setAlertMsg((msg) => ({
            ...msg,
            caption: "Success",
            message: `Email succesfully sent to ${email}. Check your mail to recover your password.`
        }) )
        
    }
   
   return (
       <div className="centered-page">
        {isLoading && <Loader />}
        { alert && 
            <AppAlert 
                caption={alertMsg?.caption}
                message={alertMsg?.message}
                accept={"Ok"}
                acceptAction={() => setAlert(false)}
                close={() => setAlert(false)}
            />
        }
           <div className="page-caption">
               <span className="wlc">OOPs! Forgot your password?</span>
               <span className="wlc-cap">Send a recovery email</span>
           </div>
           <div className="form-container">
                <div className="input-container">
                    <span className="input-cap">Email</span>
                    <input 
                        type="email" 
                        value={email} 
                        className="input"
                        placeholder="e.g. mymail@provider.com"
                        onChange={(e) => setEmail(e.target.value)} 
                        required
                    />
                </div>
    
               <button 
                className="form-button"
                onClick={handleSubmit}
               >
                    Send email
                </button>
               <div className="other-ops">
                   <span className="ops-text">Don't have an account? </span>
                   <a href="/register">Create an account</a>
               </div>
           </div>
       </div>
   );
}
