import React, {  useState } from "react";

import { useAuth } from "../../services/authentication/authentication.context";
import { LeftNav } from "../../components/main-components/left-nav";
import { RightNav } from "../../components/main-components/right-nav";
import { AppHeader } from "../../components/main-components/header";
import { AppFooter } from "../../components/main-components/footer";
import { Loader } from "../../components/utilities/loader";
import { AppAlert } from "../../components/utilities/alert";
import { formatUrl } from "../../helpers/parse-url";

import "../../styles/profile.css";

export const MyProfilePage = () => {

    const { userData, isLoading } = useAuth();

    const [alertObj, setAlertObj] = useState({
        message: null,
        caption: null
    });
    const [imagePrev, setImagePrev] = useState(null);
    const [isAlert, setisAlert] = useState(false);

    const fileBtn = React.useRef();

    React.useEffect(() => {
        console.log({mydata: userData})
    },[userData])

    const [profileData, setProfileData] =  useState({});
    const [showLeft, setShowleft] = useState(false);

    const ValidateData = () => {
        const isNull = (v) => v === undefined || v === null || v === "";
        if (isNull(profileData?.names)) {
            setisAlert(true);
            setAlertObj({
                caption: "Error! Invalid data!",
                message: "Names cannot be null",
            })
        }
    }

    return (
    <div>
        <AppHeader pageName={"myprofile"} toggleNav={() => setShowleft((l) => !l)} />
        <div className="notes-page">
            {isLoading && <Loader />}
          
            { isAlert && 
                <AppAlert 
                    caption={alertObj.caption}
                    message={alertObj.message}
                    accept={"Ok"}
                    acceptAction={() => setisAlert(false)}
                />
            }
            <LeftNav visible={showLeft} />
                <div className="main-page">
                <div className="search-caption">My profile</div>
                    <div className="question-form">
                        <div className="image-container">
                            <img
                                className="profile-iage"
                                src={
                                    imagePrev ? imagePrev : (
                                        userData?.avatar ? 
                                        formatUrl(userData?.avatar) :
                                        "/media/peer.png"
                                    )
                                }
                                alt="" 
                                width={100}
                                height={100}
                            />
                            <button 
                                className="image-upload"
                                onClick={() => fileBtn.current.click()} 
                            >
                                Add picture
                            </button>
                            <input 
                                type="file" 
                                className="file-button"
                                onChange={(e) => {
                                    setImagePrev(URL.createObjectURL(e.target.files[0]))
                                }}
                                ref={fileBtn} 
                            />
                        </div>
                        <div className="input-container">
                            <span className="input-cap">Names</span>
                            <input 
                                type="text" 
                                value={profileData?.names || userData?.names} 
                                className="input"
                                placeholder="Enter your full names"
                                onChange={(e) => setProfileData((cred) => (
                                    {...cred, names: e.target.value}
                                )) } 
                            />
                        </div>
                        <div className="input-container">
                            <span className="input-cap">Username</span>
                            <input 
                                type="text" 
                                value={profileData?.username || userData?.username} 
                                className="input"
                                placeholder="Enter your full names"
                                onChange={(e) => setProfileData((cred) => (
                                    {...cred, username: e.target.value}
                                )) } 
                            />
                        </div>
                        <div className="input-container">
                            <span className="input-cap">Phone Number</span>
                            <input 
                                type="tel" 
                                value={profileData?.phoneNumber || userData?.phoneNumber} 
                                className="input"
                                placeholder="your phone number with area code"
                                onChange={(e) => setProfileData((cred) => (
                                    {...cred, phoneNumber: e.target.value}
                                )) } 
                            />
                        </div>
                        <div className="input-container">
                            <span className="input-cap">bio</span>
                            <textarea  
                                value={profileData?.biodata || userData?.biodata}
                                cols={10}
                                rows={10} 
                                className="input"
                                placeholder="say something about yourself"
                                onChange={(e) => setProfileData((cred) => (
                                    {...cred, biodata: e.target.value}
                                )) } 
                            />
                        </div>
                        {/* <textarea 
                            className="question-input"
                            placeholder="Tell others more about yourself"
                            onChange={(e) => setProfileData((data) => (
                                {
                                    ...data,
                                    biodata: e.target.value
                                }
                            ))}
                            value={ profileData?.biodata } 
                        /> */}
                       
                        <button 
                            className="form-button"
                            onClick={(e) => {
                                e.preventDefault();
                                ValidateData();
                                console.log(profileData)
                            }}
                        >
                            Upate personal data
                        </button>
                   </div>
                </div>
            <RightNav />
        </div>
        <AppFooter />
    </div>
    );
}