import  React, { useState } from "react";

import { AppHeader } from "../../components/main-components/header";
import { AppFooter } from "../../components/main-components/footer";
import { LeftNav } from "../../components/main-components/left-nav";
import { RightNav } from "../../components/main-components/right-nav";
import classNames from "classnames";
import { loadAllEbooks, loadAllVideos } from "../../services/documents/documents.context";

import "../../styles/resources.css";
import { EbookPage } from "../../components/resources/ebooks";
import { VideoPage } from "../../components/resources/videos";

export const ResourcesPage = () => {

    const [page, setPage] = useState("ebook");
    const [ebooks, setEbooks] = useState([]);
    const [videos, setVideos] = useState([]);
    const [showLeft, setShowleft] = useState(false);

    React.useEffect(() => {
        ( async() => {
            const data = await loadAllEbooks();
            const vidData = await loadAllVideos();
            setEbooks(data);
            setVideos(vidData);
        })();
    }, []); 

    return (
        <div>
            <AppHeader  toggleNav={() => setShowleft((s) => !s)}/>
            <div className="notes-page">
            <LeftNav visible={showLeft}/>
                <div className="main-page">
                   <div className="tab-button-container">
                        <button 
                            className={classNames(
                                "tab-button",
                                {"ebook": page === "ebook"}
                            )}
                            onClick={() => setPage("ebook")}
                        >
                            Ebooks
                        </button>
                        <button 
                            className={classNames(
                                "tab-button",
                                {"video": page === "video"}
                            )}
                            onClick={() => setPage("video")}
                        >
                            Videos
                        </button>
                        <button 
                            className={classNames(
                                "tab-button",
                                {"others": page === "others"}
                            )}
                            onClick={() => setPage("others")}
                        >
                            Others
                        </button>
                   </div>
                    {page === "ebook" &&
                        <EbookPage data={ebooks}/>
                    }
                    {page === "video"  &&
                        <VideoPage data={videos} />
                    }
                    
                </div>
            <RightNav />
            </div>
            <AppFooter />
        </div>
    );
}