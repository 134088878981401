import { serverUrl } from "../configs/server"

//get all lecture notes
export const getAllNotes = () => {
    return fetch(`${serverUrl}/accounts/alldocuments`,{
        method: "GET",
        headers: {
            "Accept" : "application/json"
        },
    });
}

//get notes comments
export const getComments = (id) => {
    return fetch(`${serverUrl}/accounts/commentsbyId/${id}`,{
        method: "GET",
        headers: {
            "Accept" : "application/json"
        },
    });
}

export const getAllprojects = () => {
    return fetch(`${serverUrl}/accounts/dissertation`,{
        method: "GET",
        headers: {
            "Accept" : "application/json"
        }
    })
}

//get all ebooks
export const getAllEbboks = () => {
    return fetch(`${serverUrl}/accounts/ebooks`,{
        method: "GET",
        headers: {
            "Accept" : "application/json"
        }
    })
}

//get all videos
export const getAllVideos = () => {
    return fetch(`${serverUrl}/accounts/allvideos`,{
        method: "GET",
        headers: {
            "Accept" : "application/json"
        }
    })
}

export const getNoteById = (id) => {
    return fetch (`${serverUrl}/accounts/lecture/${id}`,{
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type" : "application/json",
        }
    })
}

//comment on a document
export const commentDoc = (id,token, comment) => {
    return fetch(`${serverUrl}/accounts/addComment/${id}`,{
        method: "POST",
        body: JSON.stringify({comment}),
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type" : "application/json",
        }
    });
};

//like document
export const likeDoc = (id, token) => {
    return fetch(`${serverUrl}/accounts/like/${id}`,{
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
        }
    });
}

//get comments
export const getPostComments = (id) => {
    return fetch(`${serverUrl}/accounts/commentsbyId/${id}`,{
        method: "GET",
        headers: {
            Accept: "application/json",
        }
    });
} 

//edit a comment
export const editComment = (id, text, token) => {
    return fetch(`${serverUrl}/accounts/editcomment/${id}`, {
        method: "PATCH",
        body: JSON.stringify({comment: text}),
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type" : "application/json",
        }
    });
}

//delete comment
export const deleteComment = (id, token) => {
    return fetch(`${serverUrl}/accounts/deletecomment/${id}`,{
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
        }
    });
}

//get all accounts
export const getAllUsers = () => {
    return fetch (`${serverUrl}/accounts/allaccounts`,{
        method: "GET",
        headers: {
            Accept: "application/json",
        }
    })
}

//upload notes
export const uploadDocument = (token, data) => {
    const createFormdata = (d) => {
        const { title,
                authors,
                publisher,
                publishedYear,
                category,
                document,
                type
            } = d;
            const dataObj = {
                title,
                authors,
                publisher,
                publishedYear,
                category,
                document,
                type
            };

            console.log(dataObj);

            const keys = Object.keys(dataObj);
            const values = Object.values(dataObj);

            const formData = new FormData();

            keys.forEach((key, indx) => {
                formData.append(key, values[indx]);
            })

            return formData;
    }

    const formData = createFormdata(data);

    //console.log(formData);

    return fetch(`${serverUrl}/accounts/upload_publication`,{
        method: "POST",
        body: formData,
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
        }
    })
}