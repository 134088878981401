//import { useLocation } from "react-router-dom";
import React, { useEffect } from "react"; 

import { useAuth } from "../../services/authentication/authentication.context";

export const AuthRedirect = () => {
    
    const { userData } = useAuth();

    useEffect(() => {
        if (!userData?.role) {
            return
        }else if (userData?.role !== "admin"){
            return window.location.href = "/myprofile";
        }else window.location.href = "/admin";
    }, [userData]);

    return (
        <div></div>
    );
}