
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import "../../styles/alert.css";

export const AppAlert = ({ 
    caption, 
    message, 
    accept, 
    reject, 
    acceptAction, 
    rejectAction,
    close
}) => {
    return (
        <div className="alertBg">
            <div className="alert-box">
                <div className="alert-top">
                    <div className="alert-caption">{caption}</div>
                    <div className="close-box">
                        <FontAwesomeIcon icon={faClose} onClick={close} />
                    </div>
                </div>
                <div className="alert-mid">{message}</div>
                <div className="alert-bottom">
                    <button 
                        className={classNames("alert-btn", "accept")}
                        onClick={acceptAction}
                    >
                        {accept}
                    </button>
                    {reject &&
                        <button 
                            className={classNames("alert-btn", "reject")}
                            onClick={rejectAction}
                        >
                            {reject}
                        </button>
                    }
                </div>
            </div>
        </div>
    );
}