import React from "react";
import classNames from "classnames";
import "../../styles/left-nav.css";

import { useAuth } from "../../services/authentication/authentication.context";
import { formatUrl } from "../../helpers/parse-url";

export const LeftNav = ({visible}) => {

    const { isAuthenticated, userData } = useAuth();



    return (
    <div className={classNames(
        {"left_nav": true}, 
        {"sliding-nav": visible},
        {"slide-back": visible === false}
        )}
    >

        <button 
            className={classNames({
                "left_nav_bttn":true, 
                "profile_button": true,
                "hidden-button": !isAuthenticated
            })} 
        >
            <span className="my_avatar">
                <img src={formatUrl(userData?.avatar)} alt="" className="my_pic" />
            </span>
            <span className="cred_box">
                <span className="my_names">{userData?.names}</span>
                <span className="my_user_name"></span>
            </span>
        </button>
        <a href="/">
        <button className="left_nav_bttn">
            <span className="left_nav_text">
                <img src="/svgs/home.svg" className="svg_imgs" alt="" />
            </span>
            <span className="left_nav_text">Home</span>
        </button>
        </a>
        <a href="/authredir">
        <button className="left_nav_bttn">
            <span className="left_nav_text">
                <img src="/svgs/dashboard.svg" className="svg_imgs" alt="" />
            </span>
            <span className="left_nav_text">Dashboard</span>
        </button>
        </a>
        <a href="/#">
        <button className="left_nav_bttn">
            <span className="left_nav_text">
                <img src="/svgs/notification.svg" className="svg_imgs" alt="" />
            </span>
            <span className="left_nav_text">Notifications</span>
        </button>
        </a>
        <a href="/resources">
        <button className="left_nav_bttn">
            <span className="left_nav_text">
                <img src="/svgs/resources.svg" className="svg_imgs" alt="" />
            </span>
            <span className="left_nav_text">Resources</span>
        </button>
        </a>
        <a href="/myprofile">
        <button className="left_nav_bttn">
            <span className="left_nav_text">
                <img src="/svgs/settings.svg" className="svg_imgs" alt="" />
            </span>
            <span className="left_nav_text">Profile</span>
        </button>
        </a>
        <a href="#/">
        <button className="left_nav_bttn">
            <span className="left_nav_text">
                <img src="/svgs/about.svg" className="svg_imgs" alt="" />
            </span>
            <span className="left_nav_text">About</span>
        </button>
        </a>
    </div>
    
    );
}