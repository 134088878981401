import  React, { useState } from "react";

import { AppHeader } from "../../components/main-components/header";
import { AppFooter } from "../../components/main-components/footer";
import { LeftNav } from "../../components/main-components/left-nav";
import { RightNav } from "../../components/main-components/right-nav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

import "../../styles/questions.css";
import { getQuestions } from "../../services/authentication/authentication.services";
import { Loader } from "../../components/utilities/loader";
import classNames from "classnames";
import { formatUrl } from "../../helpers/parse-url";
import { parseTime } from "../../helpers/parse-time";
import { useAuth } from "../../services/authentication/authentication.context";
import { AppAlert } from "../../components/utilities/alert";
import { AskChoice } from "../../components/utilities/question-choice";

export const QuestionsPage = () => {

    const [loading, setLoading] = useState(false);
    const [questions, setQuestions] = useState();
    const [isAlert, setisAlert] = useState(false);
    const [choice, setChoice] = useState(false);

    const [alertObj, setAlertObj] = useState({
        caption: null,
        message: null
    });
    const [showLeft, setShowleft] = useState(false);

    const navigate = useNavigate();

    const { isAuthenticated } = useAuth();

    const loadQuestions = async () => {
        setLoading(true);
        try {
            const response = await getQuestions();
            const res = await response.json();
            if (res?.error) {
                setLoading(false);
                console.log({error: `Questions not loaded! ${res?.error}`});
                return;
            }
            setLoading(false);
            console.log(res);
            return res;
        } catch (e) {
            setLoading(false);
            console.log({error: `Internal error! ${e}`});
            return;
        }
    }

    React.useEffect(() => {
        const controller = new AbortController();
        ( async() => {
            const data = await loadQuestions();
            if (!Array.isArray(data)) return;
            setQuestions(data);
        })();
        controller.abort();
    },[])

    const handleAuth = () => {
        if (!isAuthenticated) {
            setisAlert(true);
            setAlertObj((obj) => ({
                ...obj,
                caption: "Unauthorized!",
                message: "Please login to be able to ask questions. If you dont have an account, create an account",
            })); 
            return;   
        }
        setChoice(true);
    }

    return (
        <div>
            <AppHeader pageName={"questions"} toggleNav={()=>setShowleft((s)=>!s)}/>
            <div className="notes-page">
                {loading && <Loader />}
                {choice && 
                    <AskChoice 
                    rejectAction={() => setChoice(false)}
                    close={() => setChoice(false)} 
                    />
                }
                { isAlert && 
                    <AppAlert 
                        caption={alertObj.caption}
                        message={alertObj.message}
                        accept={"Ok"}
                        acceptAction={() => navigate("/login")}
                        reject={"Cancel"}
                        rejectAction={() => {
                            setAlertObj((o) => ({...o, caption: null, message: null}));
                            setisAlert(false);
                        }}
                        close={() => {
                            setAlertObj((o) => ({...o, caption: null, message: null}));
                            setisAlert(false);
                        }}
                    />
                }
                <LeftNav visible={showLeft}/>
                    <div className="main-page">
                    <div 
                        className="ask-box"
                        onClick={handleAuth}
                    >
                        <button className="right cap add-notes">
                            <FontAwesomeIcon icon={faPlusCircle}  className="plus-icon"/>
                            <span className="right_txt">Ask question</span>
                        </button>   
                    </div>
                        <div className="search-caption">Questions</div>
                        { questions ?
                        <div className="questions-container">
                            { questions?.map((q) => {
                                return (
                                    <button 
                                        className="question-unit" key={q?._id}
                                        onClick={() => {
                                            navigate("/answers", {
                                                state: q
                                            })
                                        }}
                                    >
                                        <span className="unit-data">
                                            <img 
                                                className="unit-avatar"
                                                src={ 
                                                    q?.poster?.avatar? 
                                                    formatUrl(q?.poster?.avatar) :
                                                    "/media/peer.png"
                                                }
                                                alt=""
                                            />
                                            <span className="unit-poster">{q?.poster?.names}</span>
                                            <span className="unit-meta">{parseTime(q?.createdAt)}</span>
                                        </span>
                                        <span className={classNames("question-cap","unit-text")}>
                                            {q?.question}
                                        </span>
                                    </button>
                                )
                            })}
                        </div>:
                        <div className="question-container">
                            <div className="search-caption">No questions asked</div>
                        </div>
                        }
                    </div>
                <RightNav />
            </div>
            <AppFooter />
        </div>
    );
}