

import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import "../../styles/alert.css";
import "../../styles/choice.css";

export const AskChoice = ({rejectAction, close }) => {

    const navigate = useNavigate();

    return (
        <div className="alertBg">
            <div className="alert-box">
                <div className="alert-top">
                    <div className="alert-caption">
                        Whom to ask?
                    </div>
                    <div className="close-box">
                        <FontAwesomeIcon icon={faClose} onClick={close} />
                    </div>
                </div>
                <div className={classNames("alert-mid","choices")}>
                    <div className="button-box" onClick={() => navigate("/addquestion")}>
                        <button className="choice-button">
                            <img src="/media/peer.png" alt="" className="choice-img" />
                        </button>
                        <span className="choice-text">Ask peer</span>
                    </div>
                    <div className="button-box">
                        <button className="choice-button" onClick={() => navigate("/asknoteai")}>
                            <img src="/media/gpt.png" alt="" className="choice-img" />
                        </button>
                        <span className="choice-text">Ask AI</span>
                    </div>
                </div>
                <div className="alert-bottom">
                   
                    
                        <button 
                            className={classNames("alert-btn", "reject")}
                            onClick={rejectAction}
                        >
                            Close
                        </button>
                    
                </div>
            </div>
        </div>
    );
}