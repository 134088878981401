import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase, faChevronDown, faChevronUp, faComments, faDesktop, faPlusCircle, faPowerOff, faUser } from "@fortawesome/free-solid-svg-icons";
import { faFileText } from "@fortawesome/free-regular-svg-icons";
import classNames from "classnames";

import "../../styles/header.css";
import "../../styles/index.css";
import { useAuth } from "../../services/authentication/authentication.context";


export const AppHeader = ({pageName, toggleNav}) => {

    const [showNav, setShowNav] = useState(false);
    const [showLeft, setShowleft] = useState(false);

    const toggleTopNav = () => {
        setShowNav((st) => !st);
    }

   const toggleLeftNav = () => {
        setShowleft((lf) => !lf)
   }
    const { isAuthenticated, logoutUser, token, googleAccountLogout, userData  } = useAuth();
    
    return (
        <header>
        <div className = "icon-container center-icon">
            <img src="/media/notebook-logo.png" alt="" className = "note-icon" />
        </div>
        <div className = "page-title">Notebook-BMS</div>
        <div className = "site-nav-button-animated">
            <button 
                className = "chevron-wrapper flex-center"
                onClick={toggleTopNav}
                
            >
                {!showNav ? 
                    <FontAwesomeIcon icon={faChevronUp} className="top-icon" /> :
                    <FontAwesomeIcon icon={faChevronDown} className="top-icon" />
                }
            </button>
        </div>
        <button 
            className = "nav-button-animated"
            onClick={() => {
                toggleLeftNav();
                toggleNav();
            }}
        >
            <div className = "hamburger-wrapper flex-center">
                <div 
                    id="top" 
                    className = {classNames("hamburger-line",{"top-line": showLeft})} 
                />
                <div 
                    id="middle" 
                    className = {classNames("hamburger-line","mid-line", {"vannish": showLeft})} 
                />
                <div 
                    id="bottom" 
                    className = {classNames("hamburger-line",{"bottom-line": showLeft} )} 
                />
            </div>
        </button>
        <div className = {classNames("nav-section",{"visible-nav": showNav})}>
            <a href="/lecture_notes">
                <button 
                    className = {classNames("nav_bttn", "lecture", {"activated": pageName === "notes"})}
                >
                    <span className = {classNames("nav_icons", "lecture_text")}>
                        <FontAwesomeIcon 
                            icon={faDesktop} 
                            className={classNames({"active-btn": pageName === "notes"})} 
                        />
                    </span>
                    <span 
                        className = {classNames(
                            "nav_text", 
                            "lecture_text",
                            {"active-btn": pageName === "notes"} 
                            )
                        } 
                        id="lec"
                    >
                        Notes
                    </span>
                </button>
            </a>
            <a href="/research">
            <button 
                className = {classNames("nav_bttn", "lecture", {"activated": pageName === "projects"})}
            >
            <span className = "nav_icons proj">
                <FontAwesomeIcon 
                    icon={faFileText}
                    className={classNames({"active-btn": pageName === "projects"})}  
                />
            </span>
            <span 
                className = {classNames(
                    "nav_text", 
                    "lecture_text",
                    {"active-btn": pageName === "projects"} 
                    )
                } 
                id="lec"
            >
                Research projects
            </span>
            </button>
            </a>
            <a href="/jobs_carreers">
            <button 
                className = {
                    classNames(
                        "nav_bttn", 
                        "jobs_nav", 
                        {"activated": pageName === "jobs"}
                    )
                }
            >
            <span 
                className = {classNames(
                    "nav_icons", 
                    "jobs_txt",
                    {"active-btn": pageName === "jobs"}
                )}
            >
                <FontAwesomeIcon icon={faBriefcase} />
            </span>
            <span 
                className = {
                    classNames(
                        "nav_text", 
                        {"active-btn": pageName === "jobs"} 
                    )} 
                    id="jobs_txt"
            >
                Careers / Jobs
            </span>
            </button>
            </a>
            <a href="/questions">
            <button 
                className = {
                    classNames(
                        "nav_bttn", 
                        "jobs_nav", 
                        {"activated": pageName === "questions"}
                    )
                }
            >
            <span 
                className = {classNames(
                    "nav_icons", 
                    "jobs_txt",
                    {"active-btn": pageName === "questions"}
                )}
            >
                <i className = 'fa fa-comments'></i>
                <FontAwesomeIcon icon={faComments} />
            </span>
            <span
                className = {
                classNames(
                    "nav_text", 
                    {"active-btn": pageName === "questions"} 
                )} 
                id="q_a_txt"
            >
                Questions
            </span>
            </button>
            </a>
            <div className = "auth_section">
            <a href="/register">
                <button className = "auth_bttn reg_bttn">
                <span className = "nav_icons">
                    <FontAwesomeIcon icon={faPlusCircle} />
                </span>
                <span className = "nav_text">Create account</span>
                </button>
            </a>
           { !isAuthenticated ? 
            ( 
                <a href="/login" className = "lgin">
                    <button className = "auth_bttn login_bttn">
                    <span className = "nav_icons inv">
                        <FontAwesomeIcon icon={faUser} />
                    </span>
                    <span className = "nav_text inv">login</span>
                    </button>
                </a>
            ) :
            (
                <button 
                    className = {classNames("auth_bttn", "logout_bttn")} 
                    id="logout"
                    onClick={() => {
                        if (userData?.authMethod === "Google"){
                            logoutUser(token);
                            googleAccountLogout();
                        }else logoutUser(token);
                    }}
                >
                    <span className = "nav_icons inv">
                        <FontAwesomeIcon icon={faPowerOff} />
                    </span>
                    <span className = "nav_text inv">logout</span>
                </button>
            )
            }
            </div>
        </div>
        
        </header>
    );
}