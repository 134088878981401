import React, { useState } from "react";
import classNames from "classnames";

import env from "react-dotenv";

import { loadAllVideos } from "../../services/documents/documents.context";

export const VideoPage = ({data, connErr}) => {

    const [pageNum, setPageNum] = useState(1);

    const paginateData = (pgNum, d) => {
        return d.slice((pgNum - 1) * 5, ((pgNum - 1) * 5) + 5);
    }
    
    const [pageData, setPageData] = useState(paginateData(1,data));
    const [srcTerm, setSrcTerm] = useState(null);

    React.useEffect(() => {
        setPageData(paginateData(pageNum, data));
        console.log(env.GOOGLE_WEB_CLIENT);
    },[data, pageNum]);

    const parseEmbedLink = (link) =>{
        const embedLink = "https://www.youtube.com/embed/";
        const splitLink = link.split("=")[1];
        return `${embedLink}`.concat(splitLink);
    }

    const searchVideos = async (searchWord) => {
        try {
            const res = await loadAllVideos();
            var reg = new RegExp(searchWord.toLowerCase());
            let substr = searchWord.slice(0,3).toLowerCase();
            var subReg = new RegExp(substr);
            let results = res.filter((term) => {
                let wordStr = term.title.toLowerCase();
                return ( wordStr.match(reg) || wordStr.match(subReg)  ) ? term : null ;
            });
           setPageData(paginateData(pageNum,results));
        } catch (e) {
            console.log({error: `Internal server error: ${e}`});
        } 
    }

    return (
        <div>
            <div className="search-caption">Search</div>
            <div className="search-box">
                
                <div className="search-input-box">
                    <img src="/svgs/search-icon.svg" alt="" width={24} className="search-icon" />
                    <input 
                        type="search" 
                        className="search-input"  
                        placeholder="Enter video title to search"
                        onChange={(e) => setSrcTerm(e.target.value)}
                    />
                </div>
                <button 
                    className="search-button"
                    onClick={() => searchVideos(srcTerm)}
                >
                    Search
                </button>
            </div>
            <div className="notes-box">
            {pageData?.map((note) => {
                return (
                    <iframe className='video-frame'
                        title={note?.title}
                        src={`${parseEmbedLink(note?.videoLink)}?autoplay=0`}>
                    </iframe>
                    );
                })}
                {connErr && 
                    <div className="notes-unit">
                        <span className="note-title">{connErr}</span>
                    </div>
                }
            </div>
            <div className="buttons-box">
                <button 
                    className={classNames({
                        "page-button": true,
                        "disabled-button": pageNum === 1
                    })}
                    disabled={pageNum === 1}
                    onClick={() => setPageNum((p) => p - 1)}
                >
                    Previous
                </button>
                <div className="page-number">{pageNum}</div>
                <button 
                        className={classNames({
                        "page-button": true,
                        "disabled-button": pageData?.length < 5
                    })}
                    disabled={false}
                    onClick={() => setPageNum((p) => p + 1)}
                >
                    Next
                </button>
            </div>
        </div>
    )
}