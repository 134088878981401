
import { getQuestions } from "../authentication/authentication.services";
import { getAllEbboks, getAllNotes, getAllprojects, getAllUsers, getAllVideos, uploadDocument } from "./documents.services";

export const getAllLectureNotes = async () => {
    try {
        const response = await getAllNotes();
        const res = await response.json();
        return res;
    } catch (e) {
        console.log({error: `Internal error: ${e}`});
    }
}

export const getAllResearchProjects = async () => {
    try {
        const response = await getAllprojects();
        const res = await response.json();
        return res
    } catch (e) {
        console.log({error: `Internal server error: ${e}`});
    }
}

export const loadAllEbooks = async () => {
    try {
        const response = await getAllEbboks();
        const res = await response.json();
        return res
    } catch (e) {
        console.log({error: `Internal error: ${e}`});
    }
}

export const loadAllVideos = async () => {
    try {
       const response = await getAllVideos();
       const res = await response.json(); 
       return res;
    } catch (e) {
        console.log({error: `Internal error: ${e}`});
    }
}


export const loadAllQuestions = async () => {
    try {
        const response = await getQuestions();
        const res = await response.json();
        console.log({questions: res})
        return res;
        
    } catch (e) {
        console.log({error: `Internal error: ${e}`});
    }
}

export const loadAllUsers = async () => {
    try {
        const response = await getAllUsers();
        const res = await response.json();
        return res;
    } catch (e) {
        console.log({error: `Internal error: ${e}`});
    }
}

export const uploadPublication = async (token, data) => {
    try {
        const response = await uploadDocument(token, data);
        const resp = await response.json();
        console.log(resp);
    } catch (e) {
        console.log({error: `Internal error: ${e}`});
    }
}