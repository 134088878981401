import { serverUrl } from "../configs/server";

//login
export const userAuth = (email, password) => {
    return fetch(`${serverUrl}/accounts/login`, {
        method: "POST",
        body: JSON.stringify({email, password}),
        headers: {
            "Content-Type" : "application/json",
            "Accept" : "application/json",
        },
    });
}

//third party auth from app server
export const googleAuthentication = (data) => {
    return fetch (`${serverUrl}/accounts/googleauth`,{
        method: "POST",
        headers: {
             Accept: 'application/json',
             "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    })
}

//goole auth with google servers
export const googleAuthPromise = (token) => {
    return fetch (`
        https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json'
        }
    })
}

//register
export const userRegistration = (data) => {
    return fetch(`${serverUrl}/accounts/register`, {
        method: "POST",
        body: JSON.stringify(data),
        headers:{
            "Content-Type" : "application/json",
            "Accept" : "application/json",
        },
    })
}

//get userdata
export const getUserData = (token) => {
   return fetch(`${serverUrl}/accounts/myaccount`,{
    method: "GET",
    headers: {
        "Accept" : "application/json",
        "Authorization" : `Bearer ${token}`
    },
   })

}

//logout
export const userLogout = (token) => {
    return fetch(`${serverUrl}/accounts/logout`, {
        method: "POST",
        headers: {
            "Accept" : "application/json",
            "Authorization" : `Bearer ${token}`
        }
    })
}


//get all questions
export const getQuestions = () => {
    return fetch (`${serverUrl}/accounts/allquestions`,{
        method: "GET",
        headers: {
            Accept: "application/json",
        }
    });
};

//ask question
export const askPeerQuestion = (token, data) => {
    return fetch(`${serverUrl}/accounts/post_question`,{
        method: "POST",
        body: JSON.stringify(data),
        headers:{
            "Content-Type" : "application/json",
            "Accept" : "application/json",
            Authorization: `Bearer ${token}`
        },
    })
}

//ask ai
export const askAiQuestion = (token, question) => {
    return fetch(`${serverUrl}/accounts/chatAI`,{
        method: "POST",
        body: JSON.stringify({
            content: question
        }),

        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
        }
    });
}


//get answers to a particular question
export const getAnswers = (id) => {
    return fetch(`${serverUrl}/accounts/answers/${id}`,{
        method: "GET",
        headers: {
            Accept: "application/json",
        }
    });
};


//update account info
export const updateAccount = (data, token) => {

    //perform fech call
    return fetch(`${serverUrl}/accounts/update`,{
        method: "PATCH",
        body: (data),
        headers: {
            "Accept" : "application/json",
            "Authorization" : `Bearer ${token}`
        }
    });
}

//send recovery email
export const sendRecoveryEmail = (email) => {
    return fetch (`${serverUrl}/accounts//recoverymail`,{
        method: "POST",
        body: JSON.stringify({ email }),
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
        }
    })
}