import  React, { useState } from "react";

import { AppHeader } from "../../components/main-components/header";
import { AppFooter } from "../../components/main-components/footer";
import { LeftNav } from "../../components/main-components/left-nav";
import { RightNav } from "../../components/main-components/right-nav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";

import "../../styles/questions.css";
import "../../styles/ask-question.css";
import "../../styles/auth.css";

import { Loader } from "../../components/utilities/loader";
import { useAuth } from "../../services/authentication/authentication.context";
import { AppAlert } from "../../components/utilities/alert";
import { categories } from "../../helpers/mock-data";


export const AddQuestion = () => {

   
    const [isAlert, setisAlert] = useState(false);
    const [alertObj, setAlertObj] = useState({
        caption: null,
        message: null
    });


    const { token, askPeerAquestion, isLoading } = useAuth();

    const [questionData, setQuestionData] = useState({
        question: null,
        field_option: null
    });
    const [showLeft, setShowleft] = useState(false);

    const handleUpload = async (token, data) => {
        try {
            const response = await askPeerAquestion(token,data);
            if (response?.message){
                setisAlert(true);
                setAlertObj((obj) => (
                    {
                        ...obj, 
                        caption: "Success",
                        message: "Question sucessfully posted!"  
                    }
                ));
                setQuestionData((d) => ({
                    ...d,
                    question: null,
                    field_option: null
                }));
            }
            
        } catch (e) {
            console.log({error: `Internal error! ${e}` });
        }
    }

    const inputRequired = () => {
        let isNull = (d) => d === "" || d === null || d === undefined;
        return (isNull(questionData?.question) || isNull(questionData?.field_option));
    }

    return (
        <div>
            <AppHeader 
                pageName={"questions"}
                toggleNav={() => setShowleft((s) => !s)} 
            />
            <div className="notes-page">
                {isLoading && <Loader />}
              
                { isAlert && 
                    <AppAlert 
                        caption={alertObj.caption}
                        message={alertObj.message}
                        accept={"Ok"}
                        acceptAction={() => setisAlert(false)}
                    />
                }
                <LeftNav visible={showLeft} />
                    <div className="main-page">
                    <div className="search-caption">Ask peer users</div>
                       <div className="ask-rules">
                         <div className="rules-caption">
                            Tips for asking questions
                         </div>
                         <div className="rules">
                            <div className="rules-unit">
                                <FontAwesomeIcon icon={faQuestionCircle} className="q-icon" />
                                <span>
                                Make your question as short as possible, and straight to the point.
                                </span>
                            </div>
                            <div className="rules-unit">
                                <FontAwesomeIcon icon={faQuestionCircle} className="q-icon" />
                                <span>
                                Make sure you use appropraite language and terminology in order to be well understood.
                                </span>
                            </div>
                         </div>
                       </div>
                       <div className="question-form">
                          <textarea 
                            className="question-input"
                            placeholder="Type your question here"
                            onChange={(e) => setQuestionData((data) => (
                                {...data, question: e.target.value}
                            ))} 
                          />
                          <div className="input-container">
                                <span className="input-cap">
                                    Category
                                    <span className="req-asterix">*</span>
                                </span>
                                <select 
                                    type="text" 
                                    name="category" 
                                    id="category" 
                                    className={classNames("input", "category")} 
                                    required
                                    onChange={(e) => setQuestionData((data) => (
                                        {...data, field_option: e.target.value}
                                    ))}
                                >
                                    <option value={null} selected disabled>
                                        ---Choose subject area---
                                    </option>
                                    {categories.map((category, i) => (
                                        <option value={category} >{category}</option>
                                    ))}
                                </select>
                            </div>
                            <button 
                                    className="form-button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (inputRequired()) {
                                            setisAlert(true);
                                            setAlertObj((err) => (
                                                {
                                                    ...err, 
                                                    caption: "Error!",
                                                    message: "Please provide all the required data"
                                                }
                                            ))
                                            return;
                                        }else{
                                            setisAlert(false);
                                            setAlertObj((err) => ({...err, caption: null, message: null}));
                                        }
                                        handleUpload(token, questionData)
                                    }}
                                >
                                    Upload question
                                </button>
                       </div>
                    </div>
                <RightNav />
            </div>
            <AppFooter />
        </div>
    );
}