import React, { useState } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faFilePdf, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { parseTime } from "../../helpers/parse-time";

export const EbookPage = ({data, connErr}) => {

    const [pageNum, setPageNum] = useState(1);
   
    

    const paginateData = (pgNum, d) => {
        return d.slice((pgNum - 1) * 5, ((pgNum - 1) * 5) + 5);
    }

    const [pageData, setPageData] = useState(paginateData(1,data));

    React.useEffect(() => {
        setPageData(paginateData(pageNum, data));
    },[data, pageNum]);

    return (
        <div>
            <div className="search-caption">Search</div>
            <div className="search-box">
                
                <div className="search-input-box">
                    <img src="/svgs/search-icon.svg" alt="" width={24} className="search-icon" />
                    <input 
                        type="search" 
                        className="search-input"  
                        placeholder="Enter ebook title to search"
                    />
                </div>
                <button className="search-button">Search</button>
            </div>
            <div className="notes-box">
            {pageData?.map((note) => {
                return (
                        <button 
                            className="notes-unit" 
                            onClick={() => window.location.href=`/viewnotes?id=${note?._id}`}
                            key={note?._id}
                        >
                            
                            <div className="note-title">
                                <FontAwesomeIcon icon={faFilePdf} color="red" className="u-icon" />
                                {note?.title}
                            </div>
                            <div className="note-data">
                                <span className="data-unit">{note?.uploader?.names}</span>
                                <span className="data-unit">
                                    {parseTime(note?.createdAt)}
                                </span>
                                <span className="data-unit">
                                    <FontAwesomeIcon 
                                        icon={faThumbsUp} className="u-icon" 
                                    />
                                    <span className="note-unit-data">
                                        {note?.likes.length}
                                    </span>
                                </span>
                                <span className="data-unit">
                                    <FontAwesomeIcon icon={faEye} className="u-icon" />
                                    <span className="note-unit-data">{note.views}</span>
                                </span>
                            </div>
                        </button>
                    );
                })}
                {connErr && 
                    <div className="notes-unit">
                        <span className="note-title">{connErr}</span>
                    </div>
                }
            </div>
            <div className="buttons-box">
                <button 
                    className={classNames({
                        "page-button": true,
                        "disabled-button": pageNum === 1
                    })}
                    disabled={pageNum === 1}
                    onClick={() => setPageNum((p) => p - 1)}
                >
                    Previous
                </button>
                <div className="page-number">{pageNum}</div>
                <button 
                        className={classNames({
                        "page-button": true,
                        "disabled-button": pageData?.length < 5
                    })}
                    disabled={false}
                    onClick={() => setPageNum((p) => p + 1)}
                >
                    Next
                </button>
            </div>
        </div>
    );
}