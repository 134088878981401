import "../../styles/animations.css";

export const Loader = () => {
    return (
        <div className="loader-screen">
            <div className="loader-icon">
                <img src="./notebook-logo.png" alt="" className="loader-image" />
            </div>
        </div>
    );
}