import React, { useState } from "react";

import "../../styles/auth.css";
import "../../styles/index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

export const ResetPassword = () => {

   const [credentials, setCredentials] = useState({
       password: null,
       repeatPassword: null,
   });

   const [toggleShow, setToggleShow] = useState(false);
   const [toggleShow1, setToggleShow1] = useState(false);

   const { password, repeatPassword } = credentials;

   return (
       <div className={classNames("scroll-page")}>
           <div className="page-caption">
               <span className="wlc">Welcome back!</span>
               <span className="wlc-cap">Create a strong password</span>
               <span className="wlc">(at least 8 characters)</span>
           </div>
           <div className="form-container">
               
               <div className="input-container">
                   <span className="input-cap">Password</span>
                   <input 
                       type={ toggleShow ? "text" : "password" } 
                       value={password} 
                       className="input"
                       placeholder="type your password"
                       onChange={(e) => setCredentials((creds) => (
                           {...creds, password: e.target.value}
                       ))} 
                   />
                   <button 
                       className="eye-icon"
                       onClick={() => setToggleShow1((t) => !t)}
                   >
                       <FontAwesomeIcon 
                           icon={ toggleShow1 ? faEye : faEyeSlash} 
                       />
                   </button>
               </div>
               <div className="input-container">
                   <span className="input-cap">Confirm password</span>
                   <input 
                       type={ toggleShow ? "text" : "password" } 
                       value={repeatPassword} 
                       className="input"
                       placeholder="type your password"
                       onChange={(e) => setCredentials((creds) => (
                           {...creds, repeatPassword: e.target.value}
                       ))} 
                   />
                   <button 
                       className="eye-icon"
                       onClick={() => setToggleShow((t) => !t)}
                   >
                       <FontAwesomeIcon 
                           icon={ toggleShow ? faEye : faEyeSlash} 
                       />
                   </button>
               </div>
               
               <button className="form-button">Reset password</button>
              
           </div>
       </div>
   );
}
