export const parseTime = function(timestamp){
    let timeObj = new Date(timestamp);
    let now = new Date(Date.now())
    let postTime = {
        date : timeObj.getDate(),
        seconds: timeObj.getSeconds(),
        minutes: timeObj.getMinutes(),
        hour: timeObj.getHours(),
        month: timeObj.getMonth(),
        year: timeObj.getFullYear(),
    }

    let thisMoment = {
        date : now.getDate(),
        seconds: now.getSeconds(),
        minutes: now.getMinutes(),
        hour: now.getHours(),
        month : now.getMonth(),
        year : now.getFullYear(),
    }

 
   let minutesEllapsed = thisMoment.minutes - postTime.minutes;
   let hoursEllapsed = thisMoment.hour - postTime.hour;
   let daysEllapsed = thisMoment.date - postTime.date;
   let monthsEllapsed = thisMoment.month - postTime.month;
   let yearsEllapsed = thisMoment.year - postTime.year;

   if (yearsEllapsed > 0){
    return yearsEllapsed === 1?  "Last year" : yearsEllapsed + " years";
   }
   else if(monthsEllapsed > 0){
     return  monthsEllapsed === 1? monthsEllapsed + " month":  monthsEllapsed + " months";
   }
   else if(daysEllapsed > 0){
    return daysEllapsed === 1 ? daysEllapsed +" day" : daysEllapsed+ " days";
   }
   else if (daysEllapsed >= 7){
    return daysEllapsed % 7 === 1 ? daysEllapsed + " week" : daysEllapsed % 7 + " weeks";
   }
   else if(hoursEllapsed > 0){
    return hoursEllapsed === 1 ? hoursEllapsed + " hour" : hoursEllapsed + " hours";
   }
   else if(minutesEllapsed >= 0){
    return minutesEllapsed === 1 ? minutesEllapsed + " minute" : minutesEllapsed + " minutes";
   }
}