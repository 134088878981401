import React, { useState } from "react";

import "../../styles/auth.css";
import "../../styles/notes.css";
import "../../styles/search.css";
import "../../styles/main.css";
import "../../styles/index.css";

import { categories } from "../../helpers/mock-data";
import { AppHeader } from "../../components/main-components/header";
import { AppFooter } from "../../components/main-components/footer";
import { LeftNav } from "../../components/main-components/left-nav";
import { Loader } from "../../components/utilities/loader";
import { useAuth } from "../../services/authentication/authentication.context";
import classNames from "classnames";
import { uploadPublication } from "../../services/documents/documents.context";

export const AddProjectPage = () => {

    const { isLoading, token } = useAuth ();

    const [bookData, setBookData] = useState({
        title: null,
        authors: null,
        publisher: null,
        publishedYear: null,
        category: null,
        document: null,
        type: "dissertation"
    });
    const [loading, setLoading] = useState(false);
    const [showLeft, setShowleft] = useState(false);

    const handleUpload = async (token , data) => {
        setLoading(true);
        await uploadPublication(token, data);
        setLoading(false);
    }

    return (
        <div>
            <AppHeader pageName={"notes"} toggleNav={() => setShowleft((s) => !s)} />
            <div className="notes-page">
            {(loading || isLoading) && <Loader />}
            <LeftNav visible={showLeft} />
                <div className="main-page">
                    <div className="search-caption">Add project</div>
                        <div className="form-container">
                            <div className="input-container">
                                <span className="input-cap">Title</span>
                                <input 
                                    type="text" 
                                    placeholder="Title of lecture notes" 
                                    className="input"
                                    onChange={(e) => setBookData((data) => 
                                        ({...data, title: e.target.value})
                                    )}
                                />
                            </div>
                            <div className="input-container">
                                <span className="input-cap">Author(s)</span>
                                <input 
                                    type="text" 
                                    className="input"
                                    placeholder="First names of authors" 
                                    onChange={(e) => setBookData((data) => 
                                        ({...data, authors: e.target.value})
                                    )}
                                />
                            </div>
                            <div className="input-container">
                                <span className="input-cap">Publisher</span>
                                <input 
                                    type="text" 
                                    className="input"
                                    placeholder="Publisher" 
                                    onChange={(e) => setBookData((data) => 
                                        ({...data, publisher: e.target.value})
                                    )}
                                />
                            </div>
                            <div className="input-container">
                                <span className="input-cap">Published year</span>
                                <input 
                                    type="number" 
                                    className="input"
                                    placeholder="Year published" 
                                    onChange={(e) => setBookData((data) => 
                                        ({...data, publishedYear: e.target.value})
                                    )}
                                    required
                                />
                            </div>
                            <div className="input-container">
                                <span className="input-cap">Published year</span>
                                <select 
                                    type="text" 
                                    name="category" 
                                    id="category" 
                                    className={classNames("input", "category")} 
                                    required
                                    onChange={(e) => setBookData((data) => (
                                        {...data, category: e.target.value}
                                    ))}
                                >
                                    <option value={null} selected disabled>
                                        ---Choose subject area---
                                    </option>
                                    {categories.map((category, i) => (
                                        <option value={category} >{category}</option>
                                    ))}
                                </select>
                            </div>
                            <input 
                                type="file"
                                className="file-button"
                                onChange={(e) => {
                                    e.preventDefault();
                                    setBookData((data) => (
                                        {...data, document: e.target.files[0]}
                                    ))
                                }} 
                            />
                            <button 
                                className="form-button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleUpload(token, bookData)
                                }}
                            >
                                Upload notes
                            </button>
                        </div>
                    </div>
                </div>
                <AppFooter />
            </div>
        
    )
}