import { useState } from "react"
import { faEdit, faTrashCan } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"

import { formatUrl } from "../../helpers/parse-url"
import { parseTime } from "../../helpers/parse-time"

export const Lecturedata = ({data}) => {

    const [dataAction, setDataAction] = useState(null);
    const [dataId, setDataId] = useState();
    const [uniData, setUnitData] = useState(null);

    return (
        <div className="data-container">
            <div className="titles-box">
                <div className={classNames("user-info","caps")}>Uploader</div>
                <div className={classNames("user-title","caps")}>Title</div>
                <div className={classNames("user-role","caps")}>Since</div>
                <div className={classNames("modify","caps")}>Edit/Delete</div>
            </div>
            <div className="info-details">
            {data?.map((d,indx) => {
                return (
                    <div 
                        className={classNames("info-box",{"even": indx%2 === 0})}
                        key={indx}
                    >
                        <div className="data-box">
                            <div className={classNames("user-info","info-data")}>
                                <img 
                                    src={d?.poster?.avatar ? formatUrl(d?.uploader?.avatar) : "/media/peer.png"} 
                                    alt="" 
                                    className="user-avatar"
                                />
                                {d?.uploader?.names}
                            </div>
                            <div className={classNames("user-title","info-data")}>
                                {d?.title || "Not given"}
                            </div>
                            <div className={classNames("user-role","info-data")}>
                                <span className="role-data">{parseTime(d?.createdAt)}</span>
                            </div>
                            <div className={classNames("modify","info-data")}>
                                <button 
                                    className="mod-button"
                                    onClick={ () => {
                                        setDataAction("edit");
                                        setDataId(d?._id);
                                        setUnitData(d);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faEdit} className="mod-icon" />
                                </button>
                                <button 
                                    className="mod-button"
                                    onClick={ () => {
                                        setDataAction("delete");
                                        setDataId(d?._id);
                                        setUnitData(d);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faTrashCan} className="mod-icon" />
                                </button>
                            </div>
                        </div>
                        { (dataAction === "delete" && dataId === d?._id) &&
                            <div className="del-section">
                                <span className="warning-msg">
                                    { `Do you want to delete this document? 
                                       This action is irreversible!` 
                                    }
                                </span>
                                <button className="del-mod-button">Delete</button>
                                <button 
                                    className="del-mod-button" 
                                    onClick={() => {
                                        setDataAction(null);
                                        setDataId(null);
                                        setUnitData(null);
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                        }
           
                       { (dataAction === "edit" && dataId === d?._id) &&
                            <div className="edit-section">
                                <span className="edit-msg">
                                    { `Update this document?` }
                                </span>
                                <button className="del-mod-button">Update</button>
                                <button 
                                    className="del-mod-button" 
                                    onClick={() => {
                                        setDataAction(null);
                                        setDataId(null);
                                        setUnitData(null);
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                        }
                    </div>
                )
            })}
            </div>
        </div>
    )
}