import classNames from "classnames";
import "../../styles/footer.css";

export const AppFooter = () => {
    return (
        <footer>
            <div className = "top_footer">
                <div className = "left_top">
                    <div className = "title_container">
                        <img src="/media/notebook-logo.png" alt="" className = "note-icon" />
                        <span className = {classNames("page-title", "foot-title")}>Notebook-BMS</span>
                    </div>   
                </div>
                <div className = "top_right">
                    <div className = "company_lnks">
                        <span className = "hdng">Quick Links</span>
                        <a href="/lecture_notes" className = "lnks">Lecture notes</a>
                        <a href="/research" className = "lnks">Research projects</a>
                        <a href="/jobs_carreers" className = "lnks">Carreers and Jobs</a>
                        <a href="/resources" className = "lnks">Resources</a>
                    </div>
                </div>
                <div className = "top_right">
                    <div className = "company_lnks">
                        <span className = "hdng">Company</span>
                        <a href="/about" className = "lnks">About us</a>
                        <a href="/blog" className = "lnks">Our blog</a>
                        <a href="/contact" className = "lnks">Contact us</a>
                    </div>
                    <div className = {classNames("company_lnks", "more")}>
                        <span className = "hdng">Need Help? Call us Now</span>
                        <span className = "hdng">+237 675619077</span>
                    </div>
                </div>
               
                
            </div>
            <div className = "bottom_footer">
                <div className = "copy_section"> 
                    &copy; 
                    <span className = "date-today">
                        {new Date().getFullYear()}
                    </span>
                    . All rights reserved.
                </div>
                <div className = "policy_section">legal & privacy</div>
            </div>
        </footer>
    );
}